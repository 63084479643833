<template>
  <section class="d-flex flex-column flex-fill">
    <hsPageHeader
      :title="$t('sparkmembers.coupons.views.list.components.page-header.title')"
      :sub-title="$t('sparkmembers.coupons.views.list.components.page-header.sub-title')"
      :back-text="$t('breadcrumb.ProductDashboard')"
      back-route="ProductDashboard"
    >
      <template slot="actions">
        <hs-button variant="primary" @click="$bvModal.show('coupon-creator-modal')">
          {{ $t('sparkmembers.coupons.views.list.components.page-header.create-button-label') }}
        </hs-button>
      </template>
    </hsPageHeader>
    <hsLoading v-if="isLoading" class="flex-fill" />
    <section v-else class="container d-flex flex-fill flex-column mt-4 pb-4">
      <CouponsTable id="coupons-table" :coupons="coupons" @delete="couponDeletionRequested" />
      <div class="d-flex flex-column flex-fill justify-content-end">
        <hs-pagination
          v-model="currentPage"
          :go-page-text="$t('sparkmembers.coupons.views.list.index.pagination.go-to-page')"
          :per-page="10"
          :total-rows="totalNumberOfCoupons"
          align="center"
        />
      </div>
    </section>
    <CouponCreatorModal id="coupon-creator-modal" @createButtonClick="createCoupon" />
    <hsConfirmModal
      id="delete-coupon-modal"
      icon="trash-alt"
      variant="cherry"
      :customTitle="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.title')"
      :description="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.description')"
      :ok-title="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.ok-title')"
      :cancel-title="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.cancel-title')"
      @ok="deleteCoupon"
      @cancel="couponToDelete = null"
    />
  </section>
</template>

<script>
import hsPageHeader from '@/components/_structures/PageHeader';
import CouponsTable from './components/CouponsTable';
import { analyticsService, couponService } from '@/services';
import dayjs from 'dayjs';
import generalHelper from '@/shared/helpers/general';
import { hsLoading } from '@/components';
import CouponCreatorModal from './components/CouponCreatorModal';
import { hsConfirmModal } from '@/components';
import toastHelper from '@/shared/helpers/toast';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default {
  name: 'CouponsList',
  data() {
    return {
      coupons: [],
      currentPage: 1,
      totalNumberOfCoupons: 0,
      isLoading: false,
      couponToDelete: null,
    };
  },
  watch: {
    currentPage(newVal) {
      this.mountCouponsList(newVal);
    },
  },
  methods: {
    async mountCouponsList(page) {
      this.isLoading = true;
      try {
        const res = await couponService.get([
          { key: 'per_page', value: '10' },
          { key: 'page', value: page },
          { key: 'product_ids', value: this.$route.params.id },
        ]);
        this.coupons = res.coupons.map(c => ({
          id: c.id,
          name: c.name,
          discount: c.percentual ? `${c.value}%` : generalHelper.currency(c.value / 100),
          quantity: c.quantity,
          usedQuantity: c.used_quantity,
          availableUntil: dayjs(c.available_until).format('DD/MM/YYYY'),
        }));
        this.totalNumberOfCoupons = res.total_count;
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupons-list-mounting-error')
        );
      }
      this.isLoading = false;
    },
    async createCoupon({ name, expirationDate, quantity, value, percentual }) {
      this.isLoading = true;
      this.$bvModal.hide('coupon-creator-modal');
      try {
        await couponService.create({
          name,
          available_until: dayjs(expirationDate).utc(),
          availableUntil: dayjs(expirationDate).utc(),
          quantity,
          value,
          percentual,
          product_ids: [this.$route.params.id],
          global: true,
          token_type: 'manual',
        });
        toastHelper.successMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupon-creation-success')
        );
        analyticsService.track({
          event: 'Coupon created',
          props: {
            product_id: parseInt(this.$route.params.id),
            discount: value,
            percentage: percentual,
            global: false,
          },
        });
        await this.mountCouponsList(1);
      } catch ({ response }) {
        let toastMessage = this.$t(
          'sparkmembers.coupons.views.list.index.toast-messages.coupon-creation-errors.default'
        );
        if (response.data.errors.name) {
          toastMessage = this.$t(
            'sparkmembers.coupons.views.list.index.toast-messages.coupon-creation-errors.name-not-available'
          );
        }
        toastHelper.dangerMessage(toastMessage);
      }
      this.isLoading = false;
    },
    couponDeletionRequested(cid) {
      this.couponToDelete = cid;
      this.$bvModal.show('delete-coupon-modal');
    },
    async deleteCoupon() {
      this.isLoading = true;
      try {
        await couponService.delete(this.couponToDelete);
        await this.mountCouponsList();
        this.couponToDelete = null;
        toastHelper.successMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupon-deletion-success')
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupon-deletion-error')
        );
      }
      this.isLoading = false;
    },
  },
  components: {
    hsPageHeader,
    CouponsTable,
    hsLoading,
    CouponCreatorModal,
    hsConfirmModal,
  },
  created() {
    this.mountCouponsList(1);
  },
};
</script>
