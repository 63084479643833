<template>
  <hs-modal id="coupon-creator-modal" :scrollable="false" size="lg" :hideHeader="true" @hidden="onHidden">
    <hs-group>
      <label class="font-weight-bold">
        {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.name-group.label') }}
      </label>
      <hs-input
        v-model="form.name"
        :placeholder="
          $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.name-group.input-placeholder')
        "
        :state="$v.form.name.$error ? false : null"
        @blur="$v.form.name.$touch"
      />
      <hs-form-invalid-feedback :state="!$v.form.name.$error">
        <span class="font-size-xs" v-if="!$v.form.name.required">
          {{
            $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.name-group.invalid-feedback.required')
          }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <div class="d-flex">
      <hs-group class="flex-fill">
        <label class="font-weight-bold">
          {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.available-until-group.label') }}
        </label>
        <hs-date
          v-model="form.expirationDate"
          :label-no-date-selected="$t('date.format')"
          :min="new Date()"
          value-as-date
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </hs-group>
      <hs-group class="ml-5">
        <label class="font-weight-bold">
          {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.label') }}
        </label>
        <hs-input
          v-model="form.quantity"
          :placeholder="
            $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.input-placeholder')
          "
          :state="$v.form.quantity.$error ? false : null"
          @blur="$v.form.quantity.$touch"
        />
        <hs-form-invalid-feedback :state="!$v.form.quantity.$error">
          <span class="font-size-xs" v-if="!$v.form.quantity.required">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.invalid-feedback.required'
              )
            }}
          </span>
          <span class="font-size-xs" v-if="!$v.form.quantity.integer">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.invalid-feedback.integer'
              )
            }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
    </div>
    <div class="d-flex">
      <hs-group class="w-25">
        <label class="font-weight-bold">
          {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.label') }}
        </label>
        <money
          v-model="form.value"
          class="form-control"
          :placeholder="
            $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.input-placeholder')
          "
          :prefix="!form.percentual ? 'R$ ' : ''"
          :suffix="form.percentual ? ' %' : ''"
          decimal=","
          thousands="."
          :masked="false"
          :state="$v.form.value.$error ? false : null"
          @blur.native="$v.form.value.$touch"
        />
        <hs-form-invalid-feedback :state="!$v.form.value.$error">
          <span class="font-size-xs" v-if="!$v.form.value.required">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.invalid-feedback.required'
              )
            }}
          </span>
          <span class="font-size-xs" v-if="!$v.form.value.minValue">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.invalid-feedback.min-value'
              )
            }}
          </span>
          <span class="font-size-xs" v-if="form.percentual && !$v.form.value.maxValue">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.invalid-feedback.max-value'
              )
            }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
    </div>
    <div class="d-flex justify-content-end">
      <hs-button variant="primary" :disabled="$v.form.$invalid" @click="createButtonClicked">
        {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.create-button-label') }}
      </hs-button>
    </div>
  </hs-modal>
</template>

<script>
import { hsModal } from '@/components';
import { required, integer } from 'vuelidate/lib/validators';
import { Money } from 'v-money';

export default {
  name: 'CouponCreatorModal',
  data() {
    return {
      form: {
        name: null,
        expirationDate: new Date(),
        quantity: null,
        value: 0,
        percentual: true,
      },
    };
  },
  methods: {
    createButtonClicked() {
      if (this.$v.form.$invalid) return;
      this.$emit('createButtonClick', {
        ...this.form,
        value: this.form.percentual ? this.form.value : this.form.value * 100,
      });
      this.resetForm();
    },
    resetForm() {
      this.form.name = null;
      this.form.expirationDate = new Date();
      this.form.quantity = null;
      this.form.value = 0;
      this.form.percentual = true;
      this.$v.$reset();
    },
    onHidden() {
      this.resetForm();
    },
  },
  components: {
    hsModal,
    Money,
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        quantity: {
          required,
          integer,
        },
        value: {
          required,
          minValue: v => v > 0,
          ...(this.form.percentual && {
            maxValue: v => v <= 90,
          }),
        },
      },
    };
  },
};
</script>
